<template>
  <transition name="modal">
    <div @keypress="keyPressEvent" class="modal-mask" @click="hide()" style="z-index:100!important;">
      <div id="portfolio-modal-container" class="modal-container" @click.stop>
        <template v-if="showLikeModal && requestIsDoneSuccessfully">
          <transition name="modalLikes">
            <div @click="showLikeModal = false" class="modalLikes-mask" style="overflow-y: scroll; ">
              <div class="modalLikes-container"
                   style="padding: 20px 10px; font-family: 'sofia-pro'; margin-top:40px;width:400px!important; height: 300px; overflow-y: scroll; " @click.stop>
                <div style="">
                  <div style="z-index: 9997; float: right; ">
                    <img @click="showLikeModal = false" style=" opacity: .6; cursor:pointer;width: 14px; height: 14px;"
                         :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
                  </div>
                  <div style="clear: both; height: 10px;"></div>

                  <div style=" padding: 5px; margin-left: 0; ">
                    <template v-if="loaderLikes">
                      <loader></loader>
                    </template>
                    <template v-else>
                      <div v-for="like in likeModalInfo.likes" style="height: 50px; display: flex; align-items: center;">

                        <div style="flex: 1 ">
                          <router-link :to="'/'+like.username" @click.native="showLikeModal = false;">
                            <img :src="like.avatar_url" style="height: 36px; width: 36px; border-radius: 50%;">
                          </router-link>
                        </div>

                        <router-link :to="'/'+like.username" @click.native="showLikeModal = false;"
                                     style="color: rgb(36, 36, 36);  font-size: 16px; flex:11; width: 250px;margin-left: 8px;">
                          <div style="font-size: 15px; font-weight: 500; color: #2d3640; line-height: 10px;">{{like.username}}</div>
                          <span style="font-size: 11px; color: #5e6b79; font-weight: 500;">{{ like.title }}</span>
                        </router-link>

                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>
        <template v-if="showPortfolioDenyModal && requestIsDoneSuccessfully">
          <transition name="modalLikes">
            <div @click="showPortfolioDenyModal = false" class="modalLikes-mask" style="overflow-y: scroll;">
              <div class="modalLikes-container"
                   style="padding: 20px 10px; font-family: 'sofia-pro'; margin-top:40px; width:600px!important; height: 240px; overflow-y: scroll; " @click.stop>
                <div>
                  <div style="z-index: 9997; float: right; ">
                    <img @click="showPortfolioDenyModal = false" style=" opacity: .6; cursor:pointer;width: 14px; height: 14px;"
                         :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
                  </div>
                  <div style="clear: both; height: 10px;"></div>
                  <div style="padding: 5px; margin-left: 0;">
                    <loader v-if="loaderDenyModal" />
                    <template v-else>
                      <h4 style="margin-bottom:12px;font-size: 16px; font-weight: 400; color: #2d3640;">İçerikte alttakilerden bir veya birkaç tanesi mevcut mudur?</h4>
                      <p v-for="(disapprovalReason, index) in disapprovalReasons">
                        <button @click="selectDisapproval(index)" :class="[disapprovalReason.selected ? 'selected' : '', 'editButton']">
                          Seç
                        </button>
                        <span style="color: #2d3640; font-weight: 500;">{{ disapprovalReason.title }}</span>
                        <a target="_blank" :href="disapprovalReason.url" style=" color: #5e6b79;padding-left: 5px; font-size: 12px">Detaylı bilgi...</a>
                      </p>
                      <div style="display: flex; justify-content: end">
                        <custom-button :disabled="buttonLoading"  buttonType="red" style="width: 128px; height: 38px; font-size: 14px; padding-bottom: 2px;" @click="deactivatePortfolio">Yayından Kaldır</custom-button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>
        <template v-if="showShowCaseModal">
          <ShowcaseModal :portfolio="portfolio" :selectedCarousel="selectedCarousel" :received-user="receivedUser" @close="showShowCaseModal = false"/>
        </template>
        <div v-if="requestIsDoneSuccessfully">
          <div>
            <div class="user-info-div">
              <div style="display: flex">
                <router-link :to="'/'+receivedUser.username">
                  <img class="avatar" :src="receivedUser.avatar_url"/>
                </router-link>
                <div class="title-and-username">
                  <p class="title">{{ title }}</p>
                  <p class="sub-title" v-if="portfolio.rating_user.id">{{ receivedUser.username }}, <router-link :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
                  <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
                </div>
              </div>
              <div style="margin-top: 4px; display: flex; justify-content: flex-end; ">
                <template v-if="receivedUser.username !== user.username">
                  <div v-show="likeStatus.action === 1">
                    <custom-button :disabled="buttonLoading" @click="updatePorfolioLikeStatus(uuid, likeStatus.action)" buttonType="border"  style="display:flex; align-items:center; justify-content:center;color:#8b95a1;width: 98px; height: 40px;font-size: 14px;">
                      <img id="32311" src="https://gcdn.bionluk.com/site/cicons/ic-like-empty.svg" onload="SVGInject(this)" class="like-icon"/>
                      <p style="line-height: 1!important; padding-left: 5px">[{{favCount}}]</p>
                    </custom-button>
                  </div>
                  <div v-show="likeStatus.action === -1">
                    <custom-button :disabled="buttonLoading" @click="updatePorfolioLikeStatus(uuid, likeStatus.action)"  buttonType="red"  style="display:flex; align-items:center; justify-content:center;color:#fff;width: 98px; height: 40px;font-size: 14px;">
                      <img id="123f" src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon-white"/>
                      <p style="line-height: 1!important; padding-left: 5px">[{{favCount}}]</p>
                    </custom-button>
                  </div>
                  <custom-button :disabled="buttonLoading" @click="sendMessage(receivedUser)" buttonType="dark" style=" margin-left: 20px;width: 128px; height: 38px;font-size: 14px; padding-bottom: 2px;">Mesaj Gönder</custom-button>
                </template>
                <template v-else>
                  <div class="own-icons">
                    <div v-if="calledFrom !== 'today_box'"
                         data-balloon="Sil"
                         data-balloon-pos="up"
                         @click="changeStatus(portfolio,-1)" class="own-icon-container">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="own-icon"/>
                    </div>
                    <template  v-if="calledFrom !== 'today_box'">
                      <div v-if="portfolio.status"
                           data-balloon="Yayından kaldır"
                           data-balloon-pos="up"
                           @click="changeStatus(portfolio,0)" class="own-icon-container">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-close.svg" onload="SVGInject(this)" class="own-icon"/>
                      </div>
                      <div v-else
                           data-balloon="Tekrar yayına al"
                           data-balloon-pos="up"
                           @click="changeStatus(portfolio,1)" class="own-icon-container">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" onload="SVGInject(this)" class="own-icon"/>
                      </div>
                    </template>
                    <div
                      data-balloon="Düzenle"
                      data-balloon-pos="up"
                      @click="$router.push('/panel/portfolyo/duzenle/'+portfolio.uuid)" class="own-icon-container">
                      <img src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)" class="own-icon"/>
                    </div>
                  </div>

                </template>

              </div>
            </div>

            <div class="portfolio-description">{{portfolio.description}}</div>

            <div class="stats-area">
              <div @click="likeOrShowModal" style="display: flex" :style="!favCount && receivedUser.username === user.username ? 'cursor:default!important; text-decoration:none!important' : ''">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="red-icon-liked"/>
                <p v-if="receivedUser.username === user.username || favCount" class="number">[{{favCount}}]</p>
                <p class="textt" >{{receivedUser.username === user.username || favCount ? 'Beğeni' : 'İlk beğenen sen ol'}}</p>
              </div>
              <div style="display: flex;margin-left: 20px; justify-content: space-between; width: 100%;">
                <div style="display: flex; ">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" onload="SVGInject(this)" class="eye-icon"/>
                  <p class="number">{{viewCount}}</p>
                  <p class="textt">Görüntülenme</p>

                </div>
                <div v-if="showEditorButton">
                  <custom-button :disabled="buttonLoading"  buttonType="red" style=" margin-left: 20px;width: 128px; height: 38px;font-size: 14px; padding-bottom: 2px;" @click="activateDenyPortfolioModal">Yayından Kaldır</custom-button>
                </div>
              </div>

            </div>

            <div class="portfolio-modal-big-img">
              <template v-if="allItems && allItems.length > 1">
                <div @click="nextItem('prev')" class="prev-portfolio" :style="navigationLeftArrowStyle ? 'cursor: pointer;' : 'cursor: default; opacity:0.3'">
                  <img  src="https://gcdn.bionluk.com/site/cicons/ic-arrows-left.svg" onload="SVGInject(this)" class="prev-icon"/>
                </div>
                <div  @click="nextItem('next')" class="next-portfolio" :style="navigationRightArrowStyle ? 'cursor: pointer;' : 'cursor: default; opacity:0.3'">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-arrows-right.svg" onload="SVGInject(this)" class="prev-icon"/>
                </div>
              </template>
              <div style="position: relative;">

                <div v-if="videoLoader && ((selectedItem.video_url && selectedItem.file_type === 'video') || selectedItem.file_type === 'audio')" class="loader-div">
                  <img :src="selectedItem.cover.image_url">
                  <div>
                    <loader style="height: 30px;width: 30px;"></loader>
                  </div>
                </div>

                <div class="mediaArea">
                  <div v-if="showIframe">
                    <iframe @load="deactivateIframeLoader()" v-show="!videoLoader" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                            width="793" height="447"
                            frameborder="0" scrolling="no" :src="'https://api.bionluk.com/general/video/' + uuid +'/showcasedetail_'+selectedItem.upload_id+'x'+selectedItem.cover.upload_id+'/'"></iframe>
                  </div>

                  <div v-if="!showIframe">
                    <img class="poster" :style="{width: '793px', 'cursor': (selectedItem.file_type === 'audio' || selectedItem.file_type === 'video') ? 'pointer' : 'default' }"
                         :src="selectedItem.cover.image_url"/>
                  </div>
                </div>

                <div class="portfolio-share-area" @click="clickedShareButton">
                  <social-sharing :url="'https://bionluk.com/freelancer-vitrin/'+uuid" inline-template>
                    <div class="user-socialmedia-icons">
                      <network
                        network="facebook" style="cursor: pointer;">
                        <div data-balloon="Facebook'ta paylaş"
                             data-balloon-pos="up">
                          <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/facebook.png"/>
                        </div>

                      </network>

                      <network network="twitter" style="cursor: pointer; margin-left: 20px;">
                        <div data-balloon="Twitter'da paylaş"
                             data-balloon-pos="up">
                          <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/twitter.png"/>
                        </div>
                      </network>

                      <network network="linkedin" style="cursor: pointer; margin-left: 20px;">
                        <div data-balloon="Linkedin'de paylaş"
                             data-balloon-pos="up">
                          <img style="width: 26px; height: 26px; border-radius: 50%"src="https://gcdn.bionluk.com/site/cicons/png/linkedin.png"/>
                        </div>
                      </network>
                    </div>
                  </social-sharing>
                  <div @click="copyToClipboard()" style="margin-left: 20px;"
                       data-balloon="Direkt linki kopyala"
                       data-balloon-pos="up">
                    <img  class="share-icon" style="width: 26px; height: 26px; border-radius: 50%; cursor: pointer" src="https://gcdn.bionluk.com/site/cicons/png/link.png"/>
                  </div>

                </div>
                <div v-if="!showIframe" class="show-attachment"  @click="showShowCaseModal = true">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-search.svg" onload="SVGInject(this)" class="show-attachment-icon"/>
                </div>
              </div>
            </div>


            <div v-if="items && items.length > 1" class="portfolio-carusel">
              <carousel :perPage="5" :scrollPerPage="true" :navigationEnabled="true" navigationNextLabel="" navigationPrevLabel="" :paginationEnabled="false">
                <slide v-for="(item, index) in items" :key="index" :style="index % 5 !== 0 ? 'margin-left: 20px' : ''">
                  <div @click="selectedCarousel = index" :class="selectedCarousel === index ? 'attachment-selected' : 'attachment'">
                    <img @click="selectItem(item)" :id="'carusel-image' + index" class="attachment-img"
                         :src="item.cover.image_url_small"/>
                  </div>
                </slide>
              </carousel>
            </div>

            <div v-if="items && items.length > 1" class="sepp"></div>

          </div>

          <div class="portfolio-modal-comment-main">

            <div class="modal-comment-header"><span v-if="commentCount === 0">Bu portfolyoyu beğendin mi? O zaman ilk yorumu sen yap...</span><span v-else>Yorumlar</span></div>
            <div class="modal-comment-body">


              <div :id="'comment_message'+message.id" v-for="(message, mindex) in comments.messages" v-if="!message.isDeleted">
                <div class="message-body">
                  <div class="message-user-info">
                    <router-link :to="'/' + message.user.username" class="message-user-avatar-username">
                      <img class="avatar-img" :src="message.user.avatar_url"/>
                    </router-link>
                    <div class="comment-username-area">
                      <div style="display: flex; justify-content: space-between">
                        <div class="comment-username">{{message.user.username}} <p class="rating" v-if="message.rating"><img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="rating-icon"/></p><p class="text" v-if="message.rating">{{message.rating}}</p></div>
                        <p class="date-text">{{message.created_at}} önce</p>
                      </div>
                      <p class="comment" v-html="message.comment"></p>
                      <div class="message-functions">
                        <div style="display: flex">
                          <template v-if="!message.reply">
                            <p class="message-function" @click="replyComment(message)">[cevapla]</p>
                          </template>
                          <template  v-if="user.username !== message.user.username">
                            <span v-if="!message.reply" style="padding-left: 5px; padding-right: 5px">-</span>
                            <p class="message-function" @click="updateCommentLikeStatus(message)" :style="message.commentLikeStatus ? 'color: #fd4056;' : ''">[{{message.commentLikeStatus ? 'beğeniyi kaldır' : 'beğen'}}]</p>
                          </template>
                          <template v-if="user.username === message.user.username || receivedUser.uuid === user.uuid">
                            <span  style="padding-left: 5px; padding-right: 5px">-</span>
                            <p class="message-function" @click="commentDelete(message)">[sil]</p>
                          </template>
                        </div>

                        <div class="message-like-count" v-if="message.commentLikeCount > 0">
                          <div @click="getUserLikes('portfolio_comment', message.id)" style="display: flex" v-show="message.commentLikeStatus">
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="comments-like-icon-liked"/>
                            <p class="liked">[{{message.commentLikeCount}}]</p>
                          </div>
                          <div @click="getUserLikes('portfolio_comment', message.id)" style="display: flex" v-show="!message.commentLikeStatus">
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="comments-like-icon"/>
                            <p class="not-liked">[{{message.commentLikeCount}}]</p>
                          </div>
                        </div>
                      </div>

                      <div v-if="message.reply">
                        <div style="background-color: rgba(191, 200, 210,0.5);height: 1px;width: 567px;margin-top:25px;"></div>
                        <div class="replied-message">
                          <router-link :to="'/' + receivedUser.username" class="message-user-avatar-username">
                            <img class="avatar-img-replied" :src="receivedUser.avatar_url"/>
                          </router-link>
                          <div style="margin-left: 16px">
                            <p class="comment-username">{{receivedUser.username}}</p>
                            <div class="comment" style="margin-top: 7px;" v-html="`<span style='color:#00a575; font-weight: 600'>@`+message.user.username+`</span> `+message.reply"></div>
                            <div class="message-functions">
                              <p  v-if=" receivedUser.uuid === user.uuid" class="message-function" @click="replyDelete(message, mindex)">[sil]</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div v-if="mindex === 0 && comments.messages.length > 1" class="go-gig">
                  <img class="avatar-go-gig" :src="receivedUser.avatar_url">
                  <p class="text-go-gig"><span style="font-weight: 600;">{{receivedUser.username}} </span>kullanıcısının işlerini beğendiysen;<br/> <span style="font-size: 16px;color: #eaedf2">iş ilanlarını incelemeni tavsiye ederiz. Belki de ihtiyacın olan o işi birlikte yaparsın.</span> 😉</p>
                  <button @click="pushToProfile" class="cuper-white-button" style="cursor: pointer; width: 150px; height: 44px; margin-left: 15px">İlanlara Göz at</button>
                </div>
              </div>


              <div style="position:relative;">
                <div :class="{'emoji-img': true, 'emoji-img-active': openEmojiPicker}" @click="openEmojiPicker = !openEmojiPicker"></div>
                <emoji-picker class="emoji-picker"
                              v-click-outside="pageClickHandler"
                              v-if="openEmojiPicker"
                              :emojiSize="32"
                              :emojisByRow="7"
                              :showSearch="true"
                              :i18n="{ search: 'Ara...'}"
                              @select="addEmoji"/>

              </div>
              <textarea @focus="textAreaFocused = true"  @focusout="textAreaFocused = false" @click="openEmojiPicker = false" v-model="newComment" id="comment-input" maxlength="300" class="comment-text-area"></textarea>
              <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
                {{newComment.length}} / <span style="color: #2d3640">300</span>
              </div>


              <div style="display: flex; flex-direction: row-reverse; margin-top: 20px;">
                <button style="width: 160px; height: 54px;" @click="saveComment()" :disabled="buttonLoading || !newComment.length" class="cuper-green-button">Gönder</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!requestIsDoneSuccessfully"  style="display: flex; align-items: center; justify-content: center; height: 500px">
          <loader/>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
  import { VEmojiPicker } from "v-emoji-picker-bi"
  import CustomButton from "../../components/CustomButton"
  import ShowcaseModal from "../../components/ShowcaseModal"
  import Api from '@/utils/request'
  export default {
    name: "src-pages-modals-portfolio-v4",
    components: {
      ShowcaseModal,
      CustomButton,emojiPicker: VEmojiPicker
    },
    data() {
      return {
        selectedDisapprovalIds: [],
        loaderDenyModal: true,
        showPortfolioDenyModal: false,
        showShowCaseModal: false,
        textAreaFocused: false,
        calledFrom:null,
        commentCount: null,
        description: null,
        favCount: null,
        fileUrl: null,
        title: null,
        receivedUser: {},
        uuid: null,
        viewCount: null,
        comments: [],
        newComment: '',
        likeStatus: null,
        fileType: null,
        videoURL: null,
        imageURLOriginal: null,
        likes: [],
        showLikeModal: null,
        disableFollowButton: null,
        loaderLikes: null,
        items: [],
        disapprovalReasons: [],
        videoLoader: false,
        requestIsDoneSuccessfully: false,
        likeModalInfo: {},
        portfolio:{},
        buttonLoading:false,
        gigId:null,

        swiperOption: {
          notNextTick: true,
          direction: 'horizontal',
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          slidesPerView: 1,
          observeParents: true
        },

        selectedCarousel:0,
        selectedItem:{},

        openEmojiPicker: false,

        repliedCommentId:null,
        allItems:[]

      }
    },
    methods: {
      deactivatePortfolio() {
        this.buttonLoading = true
        return Api.post('/editors/deny-portfolio', { uuid: this.portfolio.uuid, disapprovalReasonIds: this.selectedDisapprovalIds })
          .then(({ data: credit }) => {
            this.$toasted.global.infoToast({ description: `Teşekkürler, ${credit} kredi yüklendi.` })
            setTimeout(() => location.reload(), 1000)
          })
          .catch(err => {
            this.buttonLoading = false
            if ([403, 404].includes(err.status)) {
              this.$toasted.global.errorToast({ description: err.data.message })
            } else {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
            }
          })
      },
      selectDisapproval(index) {
        if (!this.disapprovalReasons[index].selected) {
          this.selectedDisapprovalIds.push(this.disapprovalReasons[index].id)
        } else {
          let selectedIdsIndex = this.selectedDisapprovalIds.indexOf(this.disapprovalReasons[index].id)
          if (index > -1) {
            this.selectedDisapprovalIds.splice(selectedIdsIndex, 1)
          }
        }
        this.disapprovalReasons[index].selected = ! this.disapprovalReasons[index].selected
      },
      activateDenyPortfolioModal() {
        this.showPortfolioDenyModal = true
        this.loaderDenyModal = true
        return Api.get('/editors/portfolio-reasons')
          .then(({ data }) => {
            this.disapprovalReasons = data.map(reason => {
              reason.selected = false
              return reason
            })
          })
          .catch(err => {
            if (err.status === 403) {
              this.$toasted.global.errorToast({ description: err.data.message })
            } else {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
            }
          })
          .finally(() => {
            this.loaderDenyModal = false
          })
      },
      pushToProfile(){
        this.$router.push('/'+this.receivedUser.username);
        this.hide()
      },
      keyPressEvent(event) {
        if(this.textAreaFocused) return false;
        if (event.keyCode === 39) { //next
          this.nextItem('next');
        }

        if (event.keyCode === 37) { //prev
          this.nextItem('prev');
        }
      },
      nextItem(type){
        if(!this.requestIsDoneSuccessfully) return false;

        if(type === 'prev' && this.navigationLeftArrowStyle){
          const itemIndex = this.allItems.findIndex(({ uuid }) => uuid === this.uuid)
          this.uuid = this.allItems[itemIndex - 1].uuid
          this.$store.state.clickedFrom = 'portfolio modal navigation'
          this.getPortfolio();
        }

        if (type === 'next' && this.navigationRightArrowStyle) {
          const itemIndex = this.allItems.findIndex(({ uuid }) => uuid === this.uuid)
          this.uuid = this.allItems[itemIndex + 1].uuid
          this.$store.state.clickedFrom = 'portfolio modal navigation'
          this.getPortfolio();
        }

      },
      sendMessage(user) {

        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {

          if(this.buttonLoading === false){
            this.buttonLoading = true;
            this.api.workstation.chatOpen(user.username, 0,1)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  let conversationSendData = {
                    r_username: user.username,
                    r_avatar_url: user.avatar_url,
                    r_id: user.user_id,
                    r_uuid: user.uuid,
                    fromWhere: 'Portfolio Detail',
                    c_uuid: result.data.c_uuid,
                  };
                  this.EventBus.$emit('externalOpenChat', conversationSendData);
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
                this.buttonLoading = false;
              })
              .catch(err => {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });
          }
        }

      },
      changeStatus(portfolio, status) {
        if(this.disabledButtons) return false
        if (status === -1) {
          if (confirm("Portfolyondan bu içeriği silmek istediğinden emin misin? Eğer silersen ilgili iş ilanlarından da bu içeriği çıkartacağız") === true){
            this.disabledButtons = true;
            this.api.seller.deletePortfolio(portfolio.uuid, this.$Progress)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  this.$toasted.global.infoToast({description: result.message});
                  this.EventBus.$emit('updatePortfolio', {uuid:portfolio.uuid, what:'delete', type:null});
                  this.disabledButtons = false;
                  this.hide()
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                  this.disabledButtons = false;
                }
              })
              .catch(err => {
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
                this.disabledButtons = false;
              });
          }
        } else {
          this.disabledButtons = true;
          this.api.seller.changePortfolioStatus(portfolio.uuid, status, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$toasted.global.infoToast({description: result.message});
                this.disabledButtons = false;
                this.EventBus.$emit('updatePortfolio', {uuid:portfolio.uuid, what:'delete', type:null});
                this.hide();
              } else {
                this.$toasted.global.errorToast({description: result.message});
                this.disabledButtons = false;
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.disabledButtons = false;
            });
        }
      },
      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/freelancer-vitrin/'+this.uuid;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Portfolyo linki kopyalandı.'});
      },
      clickedShareButton(){
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'portfolio share buttons'});
      },
      selectItem(item){
        this.selectedItem = item;
      },
      likeOrShowModal(){
        if(this.favCount || this.receivedUser.username === this.user.username){
          if(!this.favCount) return false;
          this.getUserLikes('portfolio', this.uuid);
        } else {
          this.updatePorfolioLikeStatus(this.uuid, this.likeStatus.action)
        }
      },
      replyDelete(message, mindex){
        this.comments.messages[mindex].reply = null;
        this.api.general.portfolioCommentDelete("portfolio_reply", message.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          });
      },
      replyComment(message){
        this.newComment = '@'+message.user.username + ' ';
        const ele = document.getElementById('comment-input');
        if(message.rating){
          this.repliedCommentId = message.id;
        }
        ele.focus();
      },
      addEmoji(emoji) {
        this.newComment = this.newComment + " " + emoji.data;
        const ele = document.getElementById('comment-input');
        ele.focus();
        this.openEmojiPicker = false;
      },
      pageClickHandler(event) {
        this.openEmojiPicker = false;
      },
      openWorkstationPortfolioPage() {
        this.$router.push("/panel/portfolyo");
      },
      deactivateIframeLoader() {
        this.videoLoader = false;
      },
      getUserLikes(type, uuid) {
        this.showLikeModal = true;
        this.loaderLikes = true;
        this.api.general.getLikedUsers(type, uuid)
          .then(({data}) => {
            let result = data;
            this.loaderLikes = false;
            if (result.success) {
              this.likeModalInfo = {
                favCount: result.data.likeCount,
                likes: result.data.likes
              };
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.showLikeModal = false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.showLikeModal = false;
          });
      },

      followAction(like) {
        this.disableFollowButton = true;
        this.api.user.updateFollowStatus(like.u_uuid, like.followStatus.action,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;
            this.disableFollowButton = false;

            if (result.success) {
              like.followStatus = result.data.followStatus;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
            this.disableFollowButton = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      saveComment() {
        this.api.general.savePortfolioComment(this.uuid, this.newComment, this.repliedCommentId, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              if(result.data && result.data.saveType && result.data.saveType === 'update'){
                let mindex = this.comments.messages.findIndex(m => m.id === this.repliedCommentId);
                this.comments.messages[mindex].reply = result.data.comment;
                this.newComment = '';
                let doc_id = 'comment_message'+this.repliedCommentId;
                document.getElementById(doc_id).scrollIntoView({
                  behavior: 'smooth'
                });
              } else {
                result.data.created_at = this.calculateElapsedTime(+new Date() - 1000)
                result.data.isDeleted = false;
                result.data.commentLikeCount = 0;
                result.data.commentLikeStatus = false;
                if(result.data.comment && result.data.comment.substr(0, 1) === '@'){
                  let firstWord = result.data.comment.replace(/ .*/,'');
                  let withoutFirstWord = result.data.comment.substr(result.data.comment.indexOf(" ") + 1);
                  result.data.comment = '<a target="_blank" href="/'+firstWord.substr(1)+'" style="color:#00a575; font-weight: 600">'+firstWord+'</a> '+withoutFirstWord;
                }
                this.comments.messages.push(result.data);
                this.newComment = '';
                this.commentCount += 1;
                this.comments.commentCount = this.commentCount;
                this.EventBus.$emit('updatePortfolio', {uuid:this.portfolio.uuid, what:'comment', type:'plus'});
              }

            } else {
              this.commentCount -= 1;
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err);
            this.commentCount -= 1;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
      updatePorfolioLikeStatus(uuid, action) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.PORTFOLIO, action, uuid, null, 'modal_portfolio')
          .then(({data}) => {
            let result = data;

            if (result.success) {
              result.data.created_at = this.calculateElapsedTime(+new Date() - 1000)
              this.favCount += action;
              if(action>0){
                this.EventBus.$emit('updatePortfolio', {uuid, what:'fav_count', type:'plus'});
              } else {
                this.EventBus.$emit('updatePortfolio', {uuid, what:'fav_count', type:'minus'});
              }
            } else {
              if(action>0){
                this.EventBus.$emit('updatePortfolio', {uuid, what:'fav_count', type:'minus'});
              } else {
                this.EventBus.$emit('updatePortfolio', {uuid, what:'fav_count', type:'plus'});
              }
              this.revertLikeStatus();
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus();
          });
        this.reverseLikeStatus();
      },

      revertLikeStatus() {
        let newLikeIconText = "Beğen";
        let newLikedIconText = "Beğen";
        if (this.likeStatus.action === this.imageUrls.likeIcon.action) {
          this.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        } else {
          this.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        }
      },

      reverseLikeStatus() {
        let newLikeIconText = "Beğen";
        let newLikedIconText = "Beğen";
        if (this.likeStatus.action === this.imageUrls.likeIcon.action) {
          this.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        } else {
          this.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        }
      },

      updateCommentLikeStatus(message) {
        let action = message.commentLikeStatus ? -1 : 1;
        this.api.general.portfolioCommentLike("portfolio_comment", action, message.id)
          .then(({data}) => {
            let result = data;

            if (result.success) {
             // this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.revertOrReverseLikeStatus(message);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertOrReverseLikeStatus(message);
          });
        this.revertOrReverseLikeStatus(message);
      },

      revertOrReverseLikeStatus(message) {
        if (message.commentLikeStatus) {
          message.commentLikeCount = message.commentLikeCount - 1;
        }
        else if (!message.commentLikeStatus) {
          message.commentLikeCount = message.commentLikeCount + 1;
        }
        message.commentLikeStatus = !message.commentLikeStatus;
      },

      commentDelete(message) {
        this.api.general.portfolioCommentDelete("portfolio", message.id)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.EventBus.$emit('updatePortfolio', {uuid:this.portfolio.uuid, what:'comment', type:'minus'});
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.deleteOrUndoMessage(message);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.deleteOrUndoMessage(message);
          });
        this.deleteOrUndoMessage(message);
      },

      deleteOrUndoMessage(message) {
        message.isDeleted = !message.isDeleted;
        if (message.isDeleted) {
          this.commentCount -= 1;
        } else {
          this.commentCount += 1;
        }
        this.comments.commentCount = this.commentCount;
        let temp = this.comments;
        this.comments = Object.assign({}, temp);
      },

      getPorfolioData() {
        let info = this.$store.state.activeModal.info
        if (info && info.calledFrom) {
          this.calledFrom = info.calledFrom
        }
        if (info && info.allItems) {
          this.allItems = info.allItems
        }
        if (!this.uuid && info) {
          this.uuid = info.uuid
          if (info.gigId) {
            this.gigId = info.gigId
          }
          this.getPortfolio()
        } else {
          this.requestIsDoneSuccessfully = false
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
        }
      },

      getPortfolio(){
        this.requestIsDoneSuccessfully = false;
        this.api.seller.getPortfolio(this.uuid, null, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.requestIsDoneSuccessfully = true
              this.commentCount = result.data.portfolio.comment_count;
              this.favCount = result.data.portfolio.fav_count;
              this.viewCount = result.data.portfolio.viewCount;
              this.description = result.data.portfolio.description;
              this.fileUrl = result.data.portfolio.image_url;
              this.title = result.data.portfolio.name;
              this.receivedUser = result.data.portfolio.user;
              this.comments = result.data.portfolio.comments;
              this.likeStatus = result.data.portfolio.likeStatus;
              this.fileType = result.data.portfolio.file_type;
              this.videoURL = result.data.portfolio.video_url;
              this.imageURLOriginal = result.data.portfolio.image_url_original;
              this.items = result.data.portfolio.items;
              this.comments.messages.forEach(comment => {
                if(comment.comment && comment.comment.substr(0, 1) === '@'){
                  let firstWord = comment.comment.replace(/ .*/,'');
                  let withoutFirstWord = comment.comment.substr(comment.comment.indexOf(" ") + 1);
                  comment.comment = '<a target="_blank" href="/'+firstWord.substr(1)+'" style="color:#00a575; font-weight: 600">'+firstWord+'</a> '+withoutFirstWord;
                }
                comment.isDeleted = false;
                comment.created_at = this.calculateElapsedTime(comment.created_at)
              });

              this.likes = result.data.portfolio.likes;
              this.portfolio = result.data.portfolio;
              this.selectedItem = result.data.portfolio.items.length > 0 ? result.data.portfolio.items[0] : {};


              this.Helper.trackEvents.pageView(this.Helper.projectShowcaseAttachmentPagePropsModal(result.data.portfolio, this.gigId),'projectshowcasedetail', 'v3');
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
          })
      },
    },
    computed: {
      showIframe() {
        return (this.selectedItem.video_url && this.selectedItem.file_type === 'video') || this.selectedItem.file_type === 'audio'
      },
      navigationLeftArrowStyle() {
        const itemIndex = this.allItems.findIndex(({ uuid }) => uuid === this.uuid)
        return itemIndex > 0;
      },
      navigationRightArrowStyle() {
        const itemIndex = this.allItems.findIndex(({ uuid }) => uuid === this.uuid)
        return itemIndex > -1 && this.allItems && (itemIndex+1) !== this.allItems.length
      },
      showEditorButton() {
        return this.user && this.user.editorRoles && Number(this.user.id) !== Number(this.receivedUser.user_id) && this.user.editorRoles.indexOf("portfolio") > -1
      }
    },
    watch: {
      uuid(newVal, oldVal) {
        if(oldVal && newVal && oldVal !== newVal){
          let ele = document.getElementById('portfolio-modal-container');
          if(ele){
            ele.scrollTo(0,0);
          }
        }
      },
      showPortfolioDenyModal() {
        this.selectedDisapprovalIds = []
      }
    },

    beforeDestroy () {
      document.removeEventListener('keydown',this.keyPressEvent);
    },

    created() {
      this.getPorfolioData()
      document.addEventListener('keydown', this.keyPressEvent, false)
    }
  }

</script>

<style scoped lang="scss">


  .prev-portfolio{
    position: fixed;
    width: 50px;
    height: 50px;
    margin-left: -90px;
    margin-top: 150px;

    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
      opacity: 0.8;
      border-radius: 10px;
    }

    .prev-icon /deep/ {
      width: 26px;
      height: 26px;
      path {
        fill: #2d3640!important;
      }
    }
  }

  .next-portfolio{
    position: fixed;
    width: 50px;
    height: 50px;
    margin-left: 843px;
    margin-top: 150px;

    border-radius: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
      opacity: 0.8;
    }

    .prev-icon /deep/ {
      width: 26px;
      height: 26px;
      path {
        fill: #2d3640!important;
      }
    }
  }

  .own-icons{
    display: flex;
    .own-icon-container{
      cursor: pointer;
      margin-left: 24px;

      .own-icon /deep/ {
        width: 24px;
        height: 24px;
        path {
          fill: #bfc8d2!important;

        }
      }
      &:hover{
        .own-icon /deep/ {
          width: 24px;
          height: 24px;
          path {
            fill: #5e6b79!important;

          }
        }
      }
    }
  }

  .modal-mask {
    overflow-y: scroll;
    display: block;
  }


  .modal-container {

    padding: 0;
    margin-top: 60px;
    width: 793px !important;
    min-height: 600px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: none;
    margin-bottom: 60px;
    border-radius: 10px;

  }




  .stats-area{

    display: flex;
    width: 693px;
    margin:0 auto 20px;

    div{
      &:first-child {
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .number{
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: #2d3640;
    }

    .textt{
      margin-left: 5px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: #5e6b79;
    }

    .red-icon-liked /deep/ {
      width: 14px;
      height: 14px;
      margin-top: 6px;
      path {
        fill: #fd4056!important;
      }
    }

    .eye-icon /deep/ {
      width: 16px;
      height: 16px;
      margin-top: 5px;
      path {
        fill: #1da1f2!important;
      }
    }
  }

  .cuper-white-button{
    &:hover{
      text-decoration: none!important;
    }
  }

  .go-gig{
    width: 700px;
    height: 116px;
    border-radius: 10px;
    background-image: linear-gradient(100deg, #43c1be 1%, #54aeb8 100%);

    margin-top: 20px;
    display: flex;
    align-items: center;


    .avatar-go-gig{
      margin-left: 30px;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border:1px solid #ffffff;
    }

    .text-go-gig{
      max-width: 390px;
      margin-left: 20px;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }


  .replied-message{
    display: flex;
    margin-top: 25px;
    .avatar-img-replied{
      width: 49px;
      height: 49px;
      border-radius: 50%;
      border: 1px solid #eaedf2;
    }

  }


  .emoji-img {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 106px;
    cursor: pointer;
    z-index: 2;
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon.svg');
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;

    &:hover {
      background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg');
      width: 22px;
      height: 22px;
      right: 14px;
      top: 105px;
    }
  }

  .emoji-img-active {
    background-image: url('https://gcdn.bionluk.com/site/icon/smile/smile-icon_hover.svg') !important;
  }

  .emoji-picker {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    position: absolute;
    right: 10px;
    top: -182px;
    z-index: 3;
  }



  .message-body{
    margin-top: 20px;
    width: 700px;
    min-height: 149px;
    border-radius: 10px;
    box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: rgba(239, 243, 248,0.5);

    .message-user-info{
      display: flex;
      margin:30px 30px 20px;
      .avatar-img{
        width: 58px;
        height: 58px;
        border-radius: 50%;
        border:2px solid #eaedf2;
      }

      .comment-username-area{
        margin-left: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .comment-username{
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4b4f52;

          .text{
            font-size: 14px;
            font-weight: bold;
            padding-left: 2px;
            color: #ffbe00;
          }

          .rating{
            padding-left: 5px;
            .rating-icon /deep/ {
              width: 16px;
              height: 16px;
              margin-top: 6px;
              path {
                fill: #ffbf00!important;
              }
            }


          }
        }
        .date-text{
          font-size: 14px;
          font-weight: normal;
          color: #bfc8d2;
        }

        .comment{
          margin-top: 10px;
          font-size: 16px;
          font-weight: 300;
          line-height: 1.5;
          color: #5e6b79;
        }

        .message-functions{
          margin-top: 15px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          color: #5e6b79;
          display: flex;
          justify-content: space-between;
          .message-function{
            cursor: pointer;
            &:hover{
              text-decoration: underline;
              color: #5e6b79;
            }
          }

          .message-like-count{
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.3px;
            text-align: center;

            .not-liked{
              margin-left: 7px;
              color: #8b95a1;
              cursor: pointer;
              &:hover{
                text-decoration: underline;
              }
            }
            .liked{
              margin-left: 7px;
              cursor: pointer;
              color: #2d3640;
              &:hover{
                text-decoration: underline;
              }
            }



            .comments-like-icon /deep/ {
              width: 14px;
              height: 14px;
              margin-top: 6px;
              path {
                fill: #8b95a1!important;
              }
            }

            .comments-like-icon-liked /deep/ {
              width: 14px;
              height: 14px;
              margin-top: 6px;
              path {
                fill: #fd4056!important;
              }
            }
          }
        }
      }
    }



  }

  .sepp{
    width: 700px;
    height: 1px;
    opacity: 0.3;
    background-color: #bfc8d2;
    margin: 30px auto 0;

  }
  .portfolio-carusel {
    text-align: left;
    width: 700px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 25px;
    align-items: center;

  }

  .like-icon /deep/ {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    path {
      fill: #8b95a1!important;
    }
  }

  .like-icon-white /deep/ {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    path {
      fill: #fff!important;
    }
  }



  .portfolio-description{
    white-space: pre-wrap;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.63;
    color: #5e6b79;
    max-width: 693px;
    margin:30px 50px;
  }

  .user-info-div {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;


    .avatar {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border:2px solid #bfc8d2
    }

    .title-and-username {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 58px;

      .title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.56px;
        color: #2d3640;
      }

      .sub-title {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 300;
        color: #8b95a1;
        a{
          font-size: 16px;
          font-weight: 600;
          color: #fa4a5e;
          &:hover{
            text-decoration: underline;
          }
        }
      }

    }

  }


  .share-icon{
    img{
      width: 26px;
      height: 26px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .user-socialmedia-icons {
    display: flex;
    flex-direction: row;
    height: 26px;
  }
  .portfolio-share-area{

    position: absolute;
    top:15px;
    right:15px;
    width: 204px;
    height: 46px;
    border-radius: 10px;
    background-color: #eaedf2;
    display: flex;
    align-items: center;
    justify-content: center;


  }
  .show-attachment{
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    left: 25px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      opacity: 0.8;
    }

    .show-attachment-icon /deep/ {
      width: 26px;
      height: 26px;
      path {
        fill: #2d3640!important;
      }
    }
  }
  .portfolio-modal-big-img {
    width: 793px;
    height: 447px;
    margin-top: 16px;
    background-color: #ffffff;
  }

  .attachment-selected{

    width: 120px;
    height: 68px;
    border-radius: 10px;
    border: solid 2px #00a575;
    display: flex;
    align-items: center;
    justify-content: center;
    .attachment-img{
      width: 115px;
      border-radius: 7px;
    }
  }


  .attachment{

    width: 120px;
    height: 68px;
    border-radius: 10px;
    border: solid 2px #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    .attachment-img{
      cursor: pointer;
      width: 115px;
      border: solid 1px #eaedf2;
      border-radius: 7px;
    }
  }


  .loader-div {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      object-fit: cover
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60px;
    width: 60px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 60px;
      width: 60px;
    }
  }

  /*comment*/
  .portfolio-modal-comment-main {

    width: 700px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    .modal-comment-header {
      font-size: 20px;
      font-weight: 500;
      color: #2d3640;
    }

    .comment-text-area{
      margin-top: 23px;
      width: 653px;
      height: 70px;
      border-radius: 2px;
      border: solid 1px rgba(75, 79, 82, 0.2);
      background-color: #eff3f8;
      padding: 20px;
      opacity: 0.5;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: #2d3640;
      &:focus{
        opacity: 1;
        background-color: #fff;
      }
    }
  }






  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .selected {
    background-color: #2d3640;
    color:#FFFFFF;
  }
  .editButton {
    margin-top:15px; margin-right:8px;font-size:12px;height:32px;padding: 2px 5px 4px; min-width: 50px!important; max-width: 500px!important; width: auto!important; border-radius: 5px!important;
  }

</style>
